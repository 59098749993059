import {Component, OnInit, Input, Output, OnChanges} from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationExtras} from '@angular/router';
import {NgForm} from '@angular/forms';
import { FormControl } from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils.service';
import {$QUESTION} from "codelyzer/angular/styles/chars";

@Component({
    selector: 'app-sog-profil',
    templateUrl: './sog-profil.component.html',
    styleUrls: ['./sog-profil.component.scss']
})
export class SogProfilComponent implements OnInit, OnChanges {

    errors = [];
    savePatientError = null;

    constructor(private router: Router, private http: HttpClient, public u: UtilsService) {
    }

    @Input() showProfileComponent: string;
    @Input() searchAddress: string;
    @Input() email: string;
    @Input() mobil: string;
    @Input() post_nr: string;
    @Input() case_nr: string;
    @Input() cvr: string;
    showRegisterForm: boolean;

    ngOnInit() {
        this.showRegisterForm = false;
    }

    saveUser(e) {
        e.preventDefault();

        // let url = this.u.getFBookingAPI() + '/api/save-sog-profil';

        const data = {
            email: this.email,
            Email: this.email,
            mobil: this.mobil,
            post_nr: this.post_nr,
            case_nr: this.case_nr,
            active: true
        };


        // let url = this.u.getApi() + "/patient/save-patient";
        let url = this.u.getApi() + "/api/update-or-save-patient";


        this.http.post(url, data)
            .subscribe(
                (resp: any) => {
                    if (resp.status === 200) {
                        const token = resp.response.token;
                        localStorage.setItem('currentPatient', JSON.stringify(data));
                        this.router.navigate(['/map'], {queryParams: {token}});
                    }
                },
                (err:any) => {
                    console.log('error 2.');
                }
            );
    }

    ngOnChanges() {
    }

    registerUser(e) {

        e.preventDefault();
        console.log('asd');
    }

}
