import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';

import { Http} from "@angular/http";

import { AuthenticationService } from '../auth/service';
import { SharedService } from '../auth/sharedService';
import { UtilsService } from '../../utils.service';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {validateComponent} from "codelyzer/walkerFactory/walkerFn";

declare var require: any;
const _ = require('underscore');

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

// export function ValidatePassword(control: AbstractControl) {
//     // if (control.re)
//     return null;
// }


export class RegisterComponent implements OnInit {
    API = "";

    // modelLogin = new RegisterForm('', '');
    // notification = {
    //     message: "",
    //     type: ""
    // };
    submitted = false;
    currentUser: any;
    group: FormGroup;


    constructor(
        private fb: FormBuilder,
        private http: Http,
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        public u: UtilsService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit() {
        this.group = this.fb.group({
            username: [null, Validators.required],
            password: [null,  Validators.compose([Validators.required])]
            // re_password: [null, [Validators.required]]
        }, {validator: this.checkPasswords });

        console.log('izsaucas');
        // this.API = this.u.getApi();
        // this.authenticationService.logout();
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group

        return null;
        let pass = group.controls.password.value;
        let confirmPass = group.controls.confirmPass.value;

        return pass === confirmPass ? null : { notSame: true };
    }

    register() {
        let username = this.group.get('username').value;
        let password = this.group.get('password').value;

        if (this.group.invalid) {
            return;
        }

        console.log(" we may continue");
    }

    login() {
        // var self = this,
        //     list = {
		// 		email: $('#cpr').val(),
		// 		password: $('#password').val(),
		// 		re_password: $('#re_password').val(),
		// 	};
        //
        // let loginResponse = self.authenticationService.login(list);
        //
        // loginResponse.subscribe(data => {
        //
        //     if (data.json().status === 200) {
        //         self.authenticationService.getAllTherapists().subscribe(r => {
        //             self.authenticationService.getAuthedBookings().subscribe(r2 => {
        //                 self.authenticationService.getAllClinics().subscribe(r3 => {
        //                     this.sharedService.onSetLoggedIn.emit();
        //
        //                     var hasBookings = this.authenticationService.hasBookings(),
		// 						isLoggedIn = this.authenticationService.isLoggedIn(),
		// 						fisiqClinic;
		//
        //                     self.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        //
        //                     console.log("has bookings: ", hasBookings, "ID:", this.currentUser);
        //                     console.log("CurrentUser:", this.currentUser);
        //                     console.log("All Clinics : ", r3);
        //                     var userClinic = r3.filter(clinic => {
        //                         console.log('no lower case check anymore');
        //                         clinic.id === this.currentUser.data.clinicId;
        //                     });
        //                     console.log("USER'S clinic =>", userClinic);
        //                     if (userClinic.length === 0) {
        //                         console.error("NO USER CLINIC");
        //                         self.router.navigate(["/sog"]);
        //                     } else if (isLoggedIn) {
        //
        //                         fisiqClinic = userClinic[0].name.indexOf("FYSIQ") !== -1;
        //                         console.log("Is user's clnic fysiq? =>", fisiqClinic)
        //                         localStorage.setItem("fisiqClinic", JSON.stringify(fisiqClinic));
        //                         console.log("INSURANCE: " + self.currentUser.data.insuranceCompany);
        //                         self.router.navigate(["/sog"]);
        //
        //                         // if (self.currentUser.data.insuranceCompany == undefined || self.currentUser.data.insuranceCompany == "") {
        //                         //     self.router.navigate(["/profile"]);
        //                         // } else {
        //                         //     if (fisiqClinic) {
        //                         //         self.router.navigate(["/offer-fysiq"]);
        //                         //     } else if (hasBookings) {
        //                         //          self.router.navigate(["/offer"]);
        //                         //     } else {
        //                         //          self.router.navigate(["/find-clinic"]);
        //                         //     }
        //                         // }
        //
        //                     } else {
        //                         self.notification = {
        //                             message: "Login Mislykkedes",
        //                             type: "error"
        //                         };
        //                     }
        //                 })
        //             })
        //         });
        //         self.notification = {
        //             message: "Login Vellykket",
        //             type: "success"
        //         };
        //     } else {
        //         console.log("AFTER login ERROR -", data.json().response.msg);
        //         self.notification = {
        //             message: "Login Mislykkedes",
        //             type: "error"
        //         };
        //     }
        //
        // }, error => {
        //     console.error("error");
        // });
    }
}
